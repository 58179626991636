<template>
  <main>

    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="font-family: Inter Variable;">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link fw-semibold" :to="{ name: 'Estudiante' }">
                Perfil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link fw-semibold" :to="{ name: 'Informacion de Usuario' }">
                Ver Información
              </router-link>
            </li>
            <li class="nav-item" v-show="this.statusUser === true">
              <router-link class="nav-link fw-semibold" :to="{ name: 'Vacantes' }">
                Vacantes
              </router-link>
            </li>
            <li class="nav-item" v-show="this.statusUser === true">
              <router-link class="nav-link fw-semibold" :to="{ name: 'Postulaciones' }">
                Postulaciones
              </router-link>
            </li>
            <!-- <li class="nav-item">
                  <router-link class="nav-link fw-semibold" :to="{ name: 'About' }">
                    Acerca De
                  </router-link>
                </li> -->
          </ul>
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li><router-link class="dropdown-item" to="/" @click="logout()">Cerrar Sesión</router-link></li>
          </ul>
        </div>
      </div>
    </nav>


    <!-- Carga de Componente -->

    <body class="container">
      <router-view />
    </body>
  </main>
</template>

<script>
import { UserService } from "@/services";
export default {
  data: () => {
    return {
      statusUser: Boolean,
      dataUser: {},
      userID: "",
      nameUser: "",
      imageName: "",
      imageUrl: "/images/profile.jpg",
      imageFile: "",
      response: {
        loading: false,
      },
      urlImage: "#f5782d",
      socialMedia: [
        {
          title: "Facebook",
          icon: "fab fa-facebook",
          active: true,
        },
        {
          title: "Instagram",
          icon: "fab fa-instagram",
          active: false,
        },
        {
          title: "LinkedIn",
          icon: "fab fa-linkedin-in",
          active: false,
        },
        {
          title: "Whatsapp",
          icon: "fab fa-whatsapp",
          active: false,
        },
        {
          title: "Telegram",
          icon: "fab fa-telegram",
          active: false,
        },
      ],
    };
  },
  mounted() {
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    this.dataUser._id = this.userID;
    this.statusUser = this.dataUser.status;
    console.log(this.dataUser);
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      this.removeItem("token");
      window.location.href = "/";
    },
    removeItem(sKey, sPath, sDomain) {
      document.cookie =
        encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
    },
    async savedData(file) {
      try {
        this.response.loading = true;
        var resp = await UserService.savedImage({ file: file });
        var { data } = resp.data;
        var user = JSON.parse(localStorage.getItem("user") || null);
        user.photo = data;
        this.dataUser = user;
        this.response.loading = false;
        localStorage.setItem("user", JSON.stringify(user));
      } catch ($e) {
        this.response.loading = false;
      }
    },
  },
};
</script>
<style land="scss">
@import "../../styles/profile.scss";
</style>
